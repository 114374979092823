<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex">
        <div class="px-0" style="flex:0; min-width: 300px;">
          <v-navigation-drawer class="drawer-compliance ma-0 rounded-0 pb-6" permanent app clipped fixed olor="bgSearch" width="300">
            <v-list class="px-8 py-4">
              <v-list-item-content>
                <v-list-item-title class="subtitle-1 font-weight-bold d-inline-block text-truncate" style="max-width: 200px;">Grupo Intercorp</v-list-item-title>
              </v-list-item-content>
            </v-list>
            <v-divider />
            <v-list nav dense class="mt-4 px-8">
              <v-list-group class="mb-3" v-for="item in list" :key="item.text" v-model="item.active" no-action :ripple="false">
                <template v-slot:activator>
                  <v-list-item-content style="min-width: 160px;">
                    <v-list-item-title class="fontBody--text subtitle-1 font-weight-bold" v-text="item.text" />
                  </v-list-item-content>
                </template>
                <v-list-item class="pa-0" active-class="transparent" :ripple="false">
                  <v-list-item-content>
                    <v-text-field class="mb-2" v-model="search" autocomplete="off" background-color="bgSearch" dense hide-details outlined placeholder="Buscar..." clearable clear-icon="mdi-close" @click:clear="search = ''">
                      <template v-slot:prepend-inner>
                        <span class="ml-2 mr-n1">
                          <v-icon color="defaultGrey">mdi-magnify</v-icon>
                        </span>
                      </template>
                    </v-text-field>
                    <v-list-item-title>
                      <v-col class="px-0 py-1" v-for="child in item.items" :key="child.name">
                        <v-checkbox
                          @click="getCurrent(child.id)"
                          dense
                          color="primary"
                          hide-details
                          :key="child.name"
                        >
                          <template v-slot:label>
                            <span class="body-1">{{child.name}}
                              <span v-if="item.text === 'Empresas'" class="mt-n1 d-block caption text--disabled">
                                <span>RUC</span>
                                {{child.id}}
                              </span>
                            </span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>

            <template v-slot:append>
              <v-col class="text-center px-8 mt-n14">
                <v-btn block color="primary" :ripple="false">Aplicar</v-btn>
                <v-btn class="mt-3" block outlined :ripple="false">Cancelar</v-btn>
              </v-col>
            </template>
          </v-navigation-drawer>
        </div>
        <div style="flex: 1; min-width: 200px;">
          <v-card class="my-5 mx-8">
            <v-toolbar class="px-4 subtitle-1 fontBody--text" flat height="55">
              <v-icon color="fontBody" left>mdi-file-document-alert-outline</v-icon>
              Cumplimiento
              <v-spacer />
              <v-menu v-if="$store.state.auth.account.id" close-on-click close-on-content-click offset-y :nudge-bottom="8" left transition="slide-y-transition">
                <template v-slot:activator="{on, attrs}">
                  <v-btn class="font-weight-regular body-1" v-on="on" v-bind="attrs" color="primary" :ripple="false">
                    <v-icon size="20" left>mdi-calendar-month</v-icon>
                    Últimos 7 días
                    <v-icon size="18" right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="py-1" active-class="transparent" :ripple="false">
                    <v-list-item-title class="body-1 text--secondary font-weight-medium"> Últimos 7 días</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="py-1" active-class="transparent" :ripple="false">
                    <v-list-item-title class="body-1 text--secondary font-weight-medium"> Últimos 15 días</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="py-1" active-class="transparent" :ripple="false">
                    <v-list-item-title class="body-1 text--secondary font-weight-medium"> Últimos 30 días</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <v-row justify="center" no-gutters>
                <!-- status -->
                <v-col class="py-5 px-8">
                  <span class="font-weight-bold body-1">
                    <v-icon color="fontBody" left>mdi-file-document-check-outline</v-icon>
                    Estado de los documentos
                  </span>
                  <v-sheet class="mt-4 transparent">
                    <v-row no-gutters>
                      <v-col cols="5" class="pa-0">
                        <div class="doughnut">
                          <chart-doughnut :chart-data="chartData" :height="80" style="margin-left: -145px" />
                          <span class="pl-1 doughnut__text-doughnut subtitle-1 secondary--text font-weight-medium">10.3M</span>
                        </div>
                        <!-- <v-progress-circular class="progress-circular-pos" rounded :rotate="360" :size="90" :width="8" value="85" color="primary">
                          <span class="d-block subtitle-1 secondary--text font-weight-medium">10.3M</span>
                        </v-progress-circular> -->
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-auto mt-2">
                        <span class="d-block body-2">
                          <v-avatar class="pb-1 mr-2" size="10" color="primary" />En cumplimiento
                        </span>
                        <span class="d-block body-2">
                          <v-avatar class="pb-1 mr-2" size="10" color="var(--light-blue)" />Con problemas
                        </span>
                        <span class="d-block body-2">
                          <v-avatar class="pb-1 mr-2" size="10" color="var(--light-light-blue-lighten-2)" />No consultados
                        </span>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
                <!-- end status -->
                <v-divider vertical />

                <v-col class="py-5 px-8">
                  <span class="font-weight-bold body-1">
                    <v-icon color="fontBody" left>mdi-file-document-remove-outline</v-icon>
                    Documentos con problemas*
                  </span>
                  <v-sheet class="mt-4 pl-1 transparent">
                    <v-row no-gutters>
                      <v-col cols="6" class="py-0">
                        <!-- <v-progress-circular class="mb-5 mt-7" v-if=" loaderReport" indeterminate color="primary" :width="2" :size="24" /> -->
                        <span class="d-block text-h5">28.000</span>
                        <span class="d-block body-1 fontDraw--text">Rechazados</span>
                        <v-btn class="mt-n2 body-2" color="primary" text small :ripple="false">Generar listado</v-btn>
                      </v-col>
                      <v-col cols="6" class="py-0">
                        <!-- <v-progress-circular class="mb-5 mt-7" v-if=" loaderReport" indeterminate color="primary" :width="2" :size="24" /> -->
                        <span class="d-block text-h5 error--text">3.000</span>
                        <span class="d-block body-1 fontDraw--text">No consultados</span>
                        <v-btn class="mt-n2 body-2" color="primary" text small :ripple="false">Generar listado</v-btn>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>

                <v-divider vertical />

                <v-col class="pt-5 py-8 px-8">
                  <span class="font-weight-bold body-1">
                    <v-icon color="fontBody" left>mdi-file-document-check-outline</v-icon>
                    Con problemas por origen
                  </span>
                  <v-sheet class="mt-4 transparent">
                    <v-row no-gutters>
                      <v-col cols="5" class="pa-0">
                        <div class="doughnut">
                          <chart-doughnut :chart-data="chartData1" :height="80" style="margin-left: -145px" />
                          <span class="pl-1 doughnut__text-doughnut subtitle-1 secondary--text font-weight-medium">9.27M</span>
                        </div>
                        <!-- <v-progress-circular class="progress-circular-pos" rounded :rotate="360" :size="90" :width="8" value="85" color="primary">
                          <span class="d-block subtitle-1 secondary--text font-weight-medium">9.27M</span>
                        </v-progress-circular> -->
                      </v-col>
                      <v-col cols="6" class="pa-0 ma-auto mt-0">
                        <span class="d-block body-2">
                          <v-avatar class="pb-1 mr-2" size="10" color="primary" />POS
                        </span>
                        <span class="d-block body-2">
                          <v-avatar class="pb-1 mr-2" size="10" color="var(--light-blue)" />B.O.
                        </span>
                        <span class="d-block body-2">
                          <v-avatar class="pb-1 mr-2" size="10" color="var(--light-light-blue-lighten-2)" />CD
                        </span>
                        <span class="d-block body-2">
                          <v-avatar class="pb-1 mr-2" size="10" color="var(--dm-indigo-light)" />E-Com
                        </span>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-divider />

              <!-- table -->
              <v-data-table class="table-not-rounded border-table-compliance" :headers="headers" :items="itemsTable" @click:row="(itemsTable) => { currentItem = itemsTable; modalRedirect = true }" :items-per-page="pagination" hide-default-header disable-sort mobile-breakpoint="300" hide-default-footer style="cursor: pointer">
                <!-- header -->
                <template v-slot:header="{ props: { headers } }">
                  <VTableHeaders :headers="headers" @sort="setSort" />
                </template>
                <!-- end header -->
                <template v-slot:[`item.name`]="{item}">
                  <template v-if="item.name.length">
                    <span class="body-1 font-weight-medium">{{item.name}}</span>
                    <span class="mt-n1 fontDraw--text body-2 d-block" v-if="item.id">RUC {{item.id}}</span>
                    <span class="mt-n1 body-2 text--disabled d-block" v-else>Sin especificar</span>
                  </template>
                  <span class="body-2 text--disabled" v-else>Sin especificar</span>
                </template>
                <template v-slot:[`item.serie`]="{item}">
                  <span class="body-1 font-weight-medium">{{item.serie}}</span>
                </template>
                <template v-slot:[`item.origen`]="{item}">
                  <span class="body-1 font-weight-medium">{{item.origen}}</span>
                </template>
                <template v-slot:[`item.pse`]="{item}">
                  <v-icon v-if="!item.pse.length">mdi-minus</v-icon>
                  <v-btn v-else text @click.stop="item.pse === 'refused' ? handleErrorInf(item) : ''" :ripple="false">
                    <v-icon :color="item.pse === 'check' ? 'success' : 'error'">{{item.pse === 'check' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.ose`]="{item}">
                  <v-icon v-if="!item.ose.length">mdi-minus</v-icon>
                  <v-btn v-else text @click.stop="item.ose === 'refused' ? handleErrorInf(item) : ''" :ripple="false">
                    <v-icon :color="item.ose === 'check' ? 'success' : 'error'">{{item.ose === 'check' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.sunat`]="{item}">
                  <v-icon v-if="!item.sunat.length">mdi-minus</v-icon>
                  <v-btn v-else text @click.stop="item.sunat === 'refused' ? handleErrorInf(item) : ''" :ripple="false">
                    <v-icon :color="item.sunat === 'check' ? 'success' : 'error'">{{item.sunat === 'check' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.sap`]="{item}">
                  <v-icon v-if="!item.sap.length">mdi-minus</v-icon>
                  <v-btn v-else text @click.stop="item.sap === 'refused' ? handleErrorInf(item) : ''" :ripple="false">
                    <v-icon :color="item.sap === 'check' ? 'success' : 'error'">{{item.sap === 'check' ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline'}}</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <v-divider />
              <template v-if="showPagination || showAll">
                <v-row class="mx-0" align="center">
                  <template v-if="showPagination">
                    <v-col cols="6">
                      <span class="body-1">{{count}} registro{{count > 1 ? 's' : ''}}</span>
                    </v-col>
                    <v-col cols="6" class="text-right mr-auto">
                      <v-pagination v-model="page" :length="totalPages" total-visible="6" />
                    </v-col>
                  </template>
                </v-row>
              </template>
              <!-- end table -->
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-col>
    <!-- modal redirect -->
    <v-dialog v-model="modalRedirect" width="600" persistent no-click-animation overlay-color="overlay">
      <VModal @close="modalRedirect = false"  @redirect="handleModalRedirect" />
    </v-dialog>
    <!-- end modal redirect -->

    <!-- modal error info -->
    <v-dialog v-model="modalErrorInf" width="600" persistent no-click-animation overlay-color="overlay">
      <VModal @close="modalErrorInf = false" @redirect="handleModalRedirect" type="errorInf" />
    </v-dialog>
    <!-- end modal error info -->
  </v-row>
</template>
<script>
import ListViewMixin from '@/mixins/ListViewMixin'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VModal from '@/modules/compliance/components/VModal'
import ChartDoughnut from '@/components/charts/ChartDoughnut'

export default {
  components: {
    VTableHeaders,
    VModal,
    ChartDoughnut
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    currentItem: {},
    modalRedirect: false,
    modalErrorInf: false,
    count: 10,
    selectedItem: 0,
    search: '',
    list: [
      {
        text: 'Empresas',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Interbank',
            id: '201000053455'
          },
          {
            name: 'Intercorp Retail',
            id: '201000053458'
          },
          {
            name: 'Casa Andina',
            id: '201000053459'
          },
          {
            name: 'Supermercados Peruanos',
            id: '201000053429'
          },
          {
            name: 'Tiendas Peruanos',
            id: '201000053422'
          },
          {
            name: 'Homecenters Peruanos',
            id: '201000053412'
          },
          {
            name: 'Financiera Oh!',
            id: '201000053312'
          }
        ]
      },
      {
        text: 'Tipos de documentos',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Boleta electrónica',
            id: '01'
          },
          {
            name: 'Factura electrónica',
            id: '02'
          },
          {
            name: 'Nota de crédito',
            id: '03'
          },
          {
            name: 'Nota de debito',
            id: '04'
          },
          {
            name: 'Comprobante de retención',
            id: '05'
          },
          {
            name: 'Comprobante de percepción',
            id: '06'
          },
          {
            name: 'D.autorizado electrónico',
            id: '07'
          },
          {
            name: 'Guías de Remisión',
            id: '08'
          }
        ]
      },
      {
        text: 'Orígenes',
        disabled: true,
        active: false,
        items: [
          {
            name: 'POS',
            id: '01'
          },
          {
            name: 'B.O',
            id: '02'
          },
          {
            name: 'CD',
            id: '03'
          },
          {
            name: 'E-Com',
            id: '04'
          }
        ]
      },
      {
        text: 'Problemas',
        disabled: true,
        active: false,
        items: [
          {
            name: 'Problema 1',
            id: '01'
          },
          {
            name: 'Problema 2',
            id: '02'
          },
          {
            name: 'Problema 3',
            id: '03'
          },
          {
            name: 'Problema 4',
            id: '04'
          }
        ]
      }
    ],
    chartData: {
      labels: ['En cumplimiento', 'Con problemas', 'No consultados'],
      datasets: [
        {
          data: [85, 15, 0]
        }
      ]
    },
    chartData1: {
      labels: ['POS', 'B.O.', 'CD', 'E-Com'],
      datasets: [
        {
          data: [60, 15, 10, 15]
        }
      ]
    },
    itemsTable: [
      {
        name: 'Supermercados Peruanos',
        id: '20100070970',
        document_type: 'Factura electrónica',
        serie: 'F039 - 12345678',
        origen: 'POS',
        pse: 'check',
        ose: 'check',
        sunat: 'check',
        sap: ''
      },
      {
        name: 'Intercorp Retail',
        id: '20100053455',
        document_type: 'Boleta electrónica',
        serie: 'B011 - 12345678',
        origen: 'B.O.',
        pse: 'check',
        ose: 'check',
        sunat: 'refused',
        sap: 'check'
      },
      {
        name: 'Homecenters Peruanos',
        id: '20536557858',
        document_type: 'Boleta electrónica',
        serie: 'B001 - 12345678',
        origen: 'CD',
        pse: 'check',
        ose: 'refused',
        sunat: '',
        sap: ''
      },
      {
        name: 'Interproperties',
        id: '20100053453',
        document_type: 'Boleta electrónica',
        serie: 'B023 - 12345678 ',
        origen: 'B.O.',
        pse: 'check',
        ose: 'refused',
        sunat: 'check',
        sap: ''
      },
      {
        name: 'Supermercados Peruanos',
        id: '20100070971',
        document_type: 'Factura electrónica',
        serie: 'F039 - 12345678',
        origen: 'POS',
        pse: 'refused',
        ose: '',
        sunat: '',
        sap: 'check'
      },
      {
        name: 'Intercorp Retail',
        id: '20100053459',
        document_type: 'Boleta electrónica',
        serie: 'B011 - 12345678',
        origen: 'B.O.',
        pse: 'check',
        ose: 'check',
        sunat: 'refused',
        sap: 'check'
      },
      {
        name: 'Homecenters Peruanos',
        id: '20536557828',
        document_type: 'Boleta electrónica',
        serie: 'B001 - 12345678',
        origen: 'CD',
        pse: 'check',
        ose: 'refused',
        sunat: '',
        sap: 'check'
      },
      {
        name: 'Interproperties',
        id: '20100053913',
        document_type: 'Boleta electrónica',
        serie: 'B023 - 12345678 ',
        origen: 'B.O.',
        pse: 'check',
        ose: 'check',
        sunat: 'check',
        sap: 'refused'
      },
      {
        name: 'Interproperties',
        id: '20100052453',
        document_type: 'Boleta electrónica',
        serie: 'B023 - 12345678 ',
        origen: 'B.O.',
        pse: 'refused',
        ose: '',
        sunat: '',
        sap: 'check'
      },
      {
        name: 'Supermercados Peruanos',
        id: '20100071971',
        document_type: 'Factura electrónica',
        serie: 'F039 - 12345678',
        origen: 'POS',
        pse: 'check',
        ose: 'check',
        sunat: 'check',
        sap: ''
      }
    ],
    headers: [
      { text: 'Empresa', value: 'name', sortable: false, width: 115 },
      { text: 'T. documento', value: 'document_type', sortable: false, width: 158 },
      { text: 'Serie - Correlativo', value: 'serie', sortable: false, width: 154 },
      { text: 'Origen', value: 'origen', sortable: false, width: 79 },
      { text: 'PSE', value: 'pse', align: 'center', sortable: false, width: 68 },
      { text: 'OSE', value: 'ose', align: 'center', sortable: false, width: 71 },
      { text: 'SUNAT', value: 'sunat', align: 'center', sortable: false, width: 82 },
      { text: 'SAP', value: 'sap', align: 'center', sortable: false, width: 66 }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  methods: {
    getCurrent () {
      //
    },
    handleModalRedirect () {
      this.modalRedirect = false
      this.$router.push({name: 'DocumentsRetrieve', params: {id: 'doc_1H5BlmXOI0B6UevnDi', ...this.$route.params }}).catch(() => {})
    },
    handleErrorInf (item = {}) {
      this.modalErrorInf = true
    }
  }
}
</script>
